<template>
  <div>
    <vs-button @click="HandleItem">sdsd</vs-button>
    <vx-card>
      <div class="flex flex-wrap justify-between">
        <div vs-w="4" vs-s="12" vs-xs="12">
          <vs-input
            v-model="year"
            type="number"
            label-placeholder="سال"
          ></vs-input>
        </div>
        <div vs-w="4" vs-s="12" vs-xs="12">
          <vs-input
            v-model="month"
            type="number"
            label-placeholder="ماه"
          ></vs-input>
        </div>
        <div vs-w="4" vs-s="12" vs-xs="12">
          <div>
            <vs-select class="mt-4" v-model="postpaid">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in options"
              >
              </vs-select-item>
            </vs-select>
          </div>
        </div>
      </div>
      <div class="my-5 w-full flex flex-wrap justify-center">
        <vs-button
          v-if="(month <= 12) & (month > 0) & (year > 0)"
          @click="getExport(year, month, postpaid)"
          >test</vs-button
        >
      </div>
      <div class="w-full mt-5 flex flex-wrap justify-center">
        <a
          class="border-1 border-solid rounded"
          v-if="excel.data"
          :download="`${excel.name}_${excel.data.attachment_random_name}`"
          :href="
            `data:${excel.data.attachment_mime_type};base64,` +
            `${excel.data.attachment_content}`
          "
          >دانلو خروجی</a
        >
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      excel: "",
      // getExport =================
      postpaid: 0,
      month: "",
      year: "",
      // getExport =================
      // options ==========
      options: [
        { value: 0, text: "همه کاربران" },
        { value: 1, text: "پس پرداختی ها" },
        { value: 2, text: "پیش پرداختی ها" },
      ],
      // options ==========
    };
  },
  methods: {
    HandleItem() {
      this.$http.get('/admin/handledataexexp').then((res) => {
        console.log('handledataexexphandledataexexp', res)
      }).catch((err) => {
        console.log('err', err);
      })
    },
    getExport(year, month, postpaid) {
      this.$vs.loading();
      this.$http
        .post("/admin/excel/monthly/invoice/export", {
          year: year,
          month: month,
          is_postpaid: postpaid,
        })
        .then((res) => {
          this.excel = {
            data: res.data.params.data,
            name: `${year}-${month}`,
          };
          //   this.$vs.notify({ text: res.data.message, color: "success" });
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
